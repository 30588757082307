import AuthContext from "./store/auth-context";
import Navbar from "./components/navbar";
import Home from "./pages/Home";
import Login from "./components/login";
import NullCurve from "./pages/NullCurve";
import Device from "./pages/Device";
import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./styles/App.css";

const App = () => {
    const authCtx = useContext(AuthContext);

    return (
        <div className="bg-white">
            {authCtx.isLoggedIn && <Navbar />}
            <Routes>
                <Route>
                    {!authCtx.isLoggedIn && <Route path="/" element={<Login />} />}
                    {authCtx.isLoggedIn && (
                        <Route path="/" element={<Navigate to="/home" />} />
                    )}
                    {authCtx.isLoggedIn && (
                        <Route path="/datatable" element={<NullCurve />} />
                    )}
                    {authCtx.isLoggedIn && <Route path="/home" element={<Home />} />}
                    {authCtx.isLoggedIn && (
                        <Route path="/device/:serial" element={<Device />} />
                    )}
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Route>
            </Routes>
        </div>
    );
};

export default App;
