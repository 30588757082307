import React, { useContext, useEffect, useState } from "react";
import DataTable from "../components/DataTable";
import BarChart from "../components/LineChart";
import API from "../ApiInterface";
import AuthContext from "../store/auth-context";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    division: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        padding: "10px",
        width: "100%",
    },
    childDiv: {
        alignItems: "center",
        gap: "10px",
        padding: "10px",
    },
    graph: {
        alignItems: "center",
        padding: "20px",
    },
    datepickerDivision: {
        display: "inline-flex",
        justifyContent: "initial",
        alignItems: "initial",
        gap: "10px",
        width: "100%",
    },
});

const NullCurve = () => {
    const classes = useStyles();
    const currentDate = dayjs();
    const sixMonthsEarlier = currentDate.subtract(120, "months");
    const [allData, setAllData] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [startDate, setStartDate] = useState(sixMonthsEarlier.toDate());
    const [endDate, setEndDate] = useState(currentDate.toDate());
    const authCtx = useContext(AuthContext);

    const fetchData = async () => {
        try {
            const startJsDate = new Date(startDate);
            const endJsDate = new Date(endDate);

            const res = await API.getDevicesByDate(
                startJsDate,
                endJsDate,
                authCtx.token
            );

            const items = res.tests;
            const rows = items.map((item, index) => ({
                serial: index + 1,
                ...item,
            }));
            setAllData(rows);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [startDate, endDate]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
                <div>
                    <div className={classes.division}>
                        <div className={classes.datepickerDivision}>
                            <DatePicker
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                label="Start date"
                                value={dayjs(startDate)}
                                onChange={setStartDate}
                            />

                            <DatePicker
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                label="End date"
                                value={dayjs(endDate)}
                                onChange={setEndDate}
                            />
                        </div>
                        <IconButton aria-label="refresh" onClick={fetchData}>
                            <RefreshTwoToneIcon fontSize="large" />
                        </IconButton>
                    </div>
                </div>
                <hr></hr>

                {allData && (
                    <>
                        <DataTable
                            data={allData}
                            setSelectedData={setSelectedRows}
                            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
                        />
                        <hr></hr>
                        <div className={classes.graph}>
                            <BarChart data={selectedRows} />
                        </div>
                    </>
                )}
            </div>
        </LocalizationProvider>
    );
};

export default NullCurve;
