import React, { useContext } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import API from "../ApiInterface";
import AuthContext from "../store/auth-context";

function testDataFormatter(obj) {
    if (typeof obj !== "object" || obj === null) return obj;

    if (Array.isArray(obj)) {
        return obj.map(testDataFormatter);
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
        let formattedKeys = key.replace(/\s+/g, "_").toLowerCase();
        formattedKeys = formattedKeys.replace(/(\d)\.(\d)v/g, "$1$2v");
        acc[formattedKeys] = testDataFormatter(value);
        return acc;
    }, {});
}

const FileUpload = ({ onUploadComplete = () => {} }) => {
    const authCtx = useContext(AuthContext);

    const handleFileUpload = async (event) => {
        const files = event.target.files;
        let tempSuccessCount = 0;
        let tempErrorFiles = [];

        const filePromises = [...files].map((file) => {
            return new Promise((resolve) => {
                try {
                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        const content = e.target.result;
                        const jsonPayload = testDataFormatter(JSON.parse(content));

                        try {
                            const statusCode = await API.insertDeviceRecord(
                                jsonPayload,
                                authCtx.token
                            );
                            if (statusCode === 201) {
                                tempSuccessCount += 1;
                            } else {
                                tempErrorFiles.push({ name: file.name, statusCode });
                            }
                        } catch (error) {
                            console.error(`Failed to upload file ${file.name}:`, error);
                            tempErrorFiles.push({ name: file.name, error });
                        }
                        resolve();
                    };
                    reader.readAsText(file);
                } catch (error) {
                    console.error(`Error processing file ${file.name}:`, error);
                    tempErrorFiles.push({ name: file.name, error });
                    resolve();
                }
            });
        });

        Promise.all(filePromises).then(() => {
            if (typeof onUploadComplete === "function") {
                onUploadComplete(tempSuccessCount, tempErrorFiles);
            } else {
                console.error("onUploadComplete is not a function:", onUploadComplete);
            }
        });
    };

    return (
        <div>
            <input
                accept=".json"
                id="contained-button-file"
                multiple
                type="file"
                style={{ display: "none" }}
                onChange={handleFileUpload}
            />
            <label htmlFor="contained-button-file">
                <Button
                    variant="outlined"
                    component="span"
                    size="large"
                    startIcon={<CloudUploadIcon />}
                >
                    Upload JSON Files
                </Button>
            </label>
        </div>
    );
};

export default FileUpload;
