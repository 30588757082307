import React, { useState } from "react";
import TablePaginationActions from "./TablePaginationActions";
import { Link } from "react-router-dom";
import FileUpload from "./FileUpload";

import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  TableFooter,
  TablePagination,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles({
  table: {
    minWidth: 750,
    border: "none",
  },
  root: {
    width: "100%",
    marginTop: "10px",
    border: "none",
  },
  paper: {
    marginBottom: "10px",
    padding: "10px",
    border: "none",
  },

  division: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  childDiv: {
    display: "flex",
    gap: "10px",
  },
  cell: {
    padding: "10px",
  },
  button: {
    padding: "10px 20px",
  },
  tablePagination: {
    marginBottom: "10px",
    padding: "10px",
    border: "none",
  },
  header: {
    "& .MuiTableCell-head": {},
  },
});

const convertToCSV = (arr) => {
  let header = headCells.map((cell) => cell.label).join(",");
  const rows = arr.map((row) => {
    return [
      row.serial,
      row.date,
      row.station,
      row.model,
      row.powerOffset,
      row.status,
      ...row.noiseFloor,
      row.noiseAverage,
      ...row.targetValues,
      ...row.nullCourse,
    ].join(",");
  });
  return [header, ...rows].join("\n");
};

const downloadCSV = (csv, filename) => {
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function sortedRowArray(array, comparator) {
  const stabilizedRowArray = array.map((el, index) => [el, index]);
  stabilizedRowArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedRowArray.map((el) => el[0]);
}

const headCells = [
  { id: "serial", numeric: true, disablePadding: false, label: "Serial" },
  { id: "date", numeric: false, disablePadding: true, label: "Date" },
  { id: "station", numeric: true, disablePadding: false, label: "Station" },
  { id: "model", numeric: true, disablePadding: false, label: "Model" },
  {
    id: "powerOffset",
    numeric: true,
    disablePadding: false,
    label: "Power Offset",
  },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  {
    id: "noisefloor1",
    numeric: true,
    disablePadding: false,
    label: "Low Band Noise",
  },
  {
    id: "noisefloor2",
    numeric: true,
    disablePadding: false,
    label: "Mid Band Noise",
  },
  {
    id: "noisefloor3",
    numeric: true,
    disablePadding: false,
    label: "High Band Noise",
  },
  {
    id: "noiseavg",
    numeric: true,
    disablePadding: false,
    label: "Noise Average",
  },
  {
    id: "targetValues1",
    numeric: true,
    disablePadding: false,
    label: "Target Speed Low",
  },
  {
    id: "targetValues2",
    numeric: true,
    disablePadding: false,
    label: "Target Signal Low",
  },
  {
    id: "targetValues3",
    numeric: true,
    disablePadding: false,
    label: "Target Speed Mid",
  },
  {
    id: "targetValues4",
    numeric: true,
    disablePadding: false,
    label: "Target Signal Mid",
  },
  {
    id: "targetValues5",
    numeric: true,
    disablePadding: false,
    label: "Target Speed High",
  },
  {
    id: "targetValues6",
    numeric: true,
    disablePadding: false,
    label: "Target Signal High",
  },
  {
    id: "nullCourse",
    numeric: false,
    disablePadding: false,
    label: "Null Course",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
      <TableHead>
        <TableRow className={classes.header}>
          {/*<TableCell padding="checkbox">*/}
          <TableCell className={classes.cell}>
            <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all items" }}
            />
          </TableCell>
          {headCells.map((headCell) => {
            // Skip rendering the header for Null Course
            if (headCell.id === "nullCourse") return null;

            return (
                <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "right"}
                    sortDirection={orderBy === headCell.id ? order : false}
                    className={classes.cell}
                >
                  <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.serial ? (
                        <span className={classes.visuallyHidden}>
                    {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                  </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
  );
}

const DataTable = ({ data, setSelectedData }) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("serial");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterStation, setFilterStation] = useState("");
  const [successCount, setSuccessCount] = useState(0);
  const [errorFiles, setErrorFiles] = useState([]);

  const uniqueStations = Array.from(new Set(data.map((item) => item.station)));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const filteredRows = data.filter(
          (row) => !filterStation || row.station === filterStation
      );
      const newSelecteds = filteredRows.map((n) => n.serial);

      setSelected(newSelecteds);
      setSelectedData(filteredRows);
      return;
    }
    setSelected([]);
    setSelectedData([]);
  };

  const handleExportSelectedToCSV = () => {
    if (!selected.length) {
      alert("No rows selected!");
      return;
    }

    const selectedRows = data.filter((row) => selected.includes(row.serial));
    const csv = convertToCSV(selectedRows);
    downloadCSV(csv, "selected_data.csv");
  };

  const handleClick = (event, serial, row) => {
    const selectedIndex = selected.indexOf(serial);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, serial);
      setSelectedData((prevData) => [...prevData, row]);
    } else {
      newSelected = [...selected];
      newSelected.splice(selectedIndex, 1);

      setSelectedData((prevData) => {
        const newSelectedData = [...prevData];
        const indexToRemove = newSelectedData.findIndex(
            (item) => item.serial === serial
        );
        if (indexToRemove !== -1) {
          newSelectedData.splice(indexToRemove, 1);
        }
        return newSelectedData;
      });
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilterStation(event.target.value);
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
      <div className={classes.root}>
        <Paper elevation={5} className={classes.paper}>
          <Stack sx={{ width: "100%", marginTop: 3 }} spacing={2}>
            {successCount > 0 && (
                <Alert severity="success">{`${successCount} file(s) successfully uploaded!`}</Alert>
            )}
            {errorFiles.length > 0 &&
                errorFiles.map((file, idx) => (
                    <Alert key={idx} severity="error">
                      {`Error uploading ${file.name}.`}
                      {file.statusCode ? ` Status Code: ${file.statusCode}.` : ""}
                    </Alert>
                ))}
          </Stack>
          <TableContainer>
            <div className={classes.division}>
              <FormControl
                  variant="outlined"
                  style={{ margin: "10px 0" }}
                  sx={{ m: 1, minWidth: 120 }}
                  size="small"
              >
                <InputLabel id="station-filter-label">Station</InputLabel>
                <Select
                    labelId="station-filter-label"
                    id="station-filter"
                    value={filterStation}
                    onChange={handleFilterChange}
                    label="Station"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {uniqueStations.map((station) => (
                      <MenuItem key={station} value={station}>
                        {station}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={classes.childDiv}>
                <FileUpload
                    onUploadComplete={(success, errors) => {
                      setSuccessCount(success);
                      setErrorFiles(errors);
                    }}
                />
                <Button
                    variant="outlined"
                    color="error"
                    onClick={handleExportSelectedToCSV}
                    className={classes.button}
                >
                  Export to CSV
                </Button>
              </div>
            </div>
            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
            >
              <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={data.length}
              />
              <TableBody>
                {sortedRowArray(data, getComparator(order, orderBy))
                    .filter(
                        (row) => !filterStation || row.station === filterStation
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.serial);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                          <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.serial}
                              selected={isItemSelected}
                          >
                            <TableCell className={classes.cell}>
                              <Checkbox
                                  onClick={(event) =>
                                      handleClick(event, row.serial, row)
                                  }
                                  checked={isItemSelected}
                                  inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                className={classes.cell}
                            >
                              <Link to={`/device/${row.serial}`}>{row.serial}</Link>
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.date}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.station}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.model}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.powerOffset}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.status}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.noiseFloor[0]}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.noiseFloor[1]}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.noiseFloor[2]}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.noiseAverage[0]}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.targetValues[0]}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.targetValues[1]}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.targetValues[2]}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.targetValues[3]}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.targetValues[4]}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                              {row.targetValues[5]}
                            </TableCell>
                          </TableRow>
                      );
                    })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                      rowsPerPageOptions={[10, 20, 50, { label: "All", value: -1 }]}
                      colSpan={17}
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      className={classes.tablePagination}
                      sx={{
                        ".MuiTablePagination-displayedRows": {
                          marginTop: "",
                          marginBottom: "1em",
                        },
                        ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                            {
                              marginTop: "1em",
                              marginBottom: "1em",
                            },
                      }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </div>
  );
};

export default DataTable;
