import API from "../ApiInterface";
import AuthContext from "../store/auth-context";
import jwt_decode from "jwt-decode";
import React, { useState, useRef, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import '../styles/fanCast.css';

function Login(props) {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const emailInputRef = useRef();
    const passwordInputRef = useRef();
    const authCtx = useContext(AuthContext);

    async function login(email, password) {
        return API.login(email, password)
            .then((data) => {
                const expirationTime = new Date((new Date().getTime() + (+data.expires_in * 1000)));
                authCtx.login(data.access_token, data.refresh_token, expirationTime.toString(), false);
                return data;
            })
    }

    const submitHandler = (event) => {
        event.preventDefault();

        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;

        if (enteredEmail.length === 0) {
            alert('Please enter an email address.');
        }
        if (enteredPassword.length <= 4) {
            alert('Password must be longer than 4 characters...!');
        }

        setIsLoading(true);
        login(enteredEmail, enteredPassword).then(result => {
            setIsLoading(false);

            const decodedToken = jwt_decode(result.access_token);
            if (decodedToken.permissions && decodedToken.permissions.includes("MM")) {
                setIsLoggedIn(true);
                setError(false);

                navigate("/home", {replace:true})
            }
            else {
                setError(true);
                setIsLoggedIn(false);
            }
        }).catch((error) => {
            setError(true);
            setIsLoggedIn(false);
        });
    }

    let condContent;

    if (!isLoggedIn && error) {
        condContent = <h3 className="text-danger" style={{fontFamily: 'Maven Pro', fontSize: '3em'}}>Login Error</h3>;
    }

    if (isLoggedIn) {
        condContent = <h1 className="text-danger" style={{fontFamily: 'Maven Pro', fontSize: '3em'}}>Dashboard<span
            className="badge bg-success ms-3">Active</span></h1>;
    } else if (!isLoggedIn && !error) {
        condContent = <h1 className="text-dark" style={{fontFamily: 'Montserrat, sans-serif', fontSize: '24px'}}>Manufacturing Dashboard</h1>;
    }

    return (
        <div className="container">
            <div className="row justify-content-center my-5" style={{verticalAlign: 'bottom'}}>
                <div className="col-3 my-5"
                     style={{backgroundColor: '', height: '80vh', alignContent: 'center', width: '325px'}}>
                    <img
                        src="https://www.nicepng.com/png/full/840-8400920_pocket-radar.png"
                        alt="" height="50" style={{margin: '60px', marginLeft: '80px', marginBottom: '30px'}}/>
                    <form className="col text-center " onSubmit={submitHandler}>
                        <div className="form-outline mb-4">
                            {condContent}
                        </div>

                        {/* Email input */}
                        <div className="form-outline mb-4 ">
                            <input name="email" required ref={emailInputRef} type="email"
                                   id="form2Example1"
                                   className="form-control" placeholder="Email"/>
                        </div>

                        {/*Password input*/}
                        <div className="form-outline mb-4">
                            <input required ref={passwordInputRef} name="password" type="password"
                                   id="form2Example2" className="form-control" placeholder="Password"/>
                        </div>

                        {/*Sign in button*/}
                        <div className="form-outline mb-4">
                            {!isLoading && <button type="submit"
                                                   className="btn btn-danger btn-block mb-4 w-100 p-2">SIGN IN
                            </button>}
                            <br/>
                        </div>

                        {/*2 column grid layout for inline styling*/}
                        <div className="row justify-content-center align-self-center">
                            <div className="col d-flex justify-content-center">
                                {/*Checkbox*/}
                                <div className="form-check">
                                </div>
                            </div>

                            <div className="col">
                            </div>
                        </div>

                        {isLoading && <p className="text-light">Sending request...</p>}
                        <br/>

                        <div className="text-center" />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;