import React, { useState, useEffect, useContext } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { makeStyles } from "@mui/styles";
import { Paper } from "@mui/material";
import API from "../ApiInterface";
import AuthContext from "../store/auth-context";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

Chart.register(...registerables, annotationPlugin);

const useStyles = makeStyles({
    root: {
        width: "100%",
        marginTop: "10px",
        border: "none",
    },
    paper: {
        marginBottom: "10px",
        padding: "10px",
        border: "none",
    },
    division: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        padding: "10px",
        width: "100%",
    },
    datepickerDivision: {
        display: "inline-flex",
        justifyContent: "initial",
        alignItems: "initial",
        gap: "10px",
        width: "100%",
    },
    selectorDivision: {
        justifyContent: "end",
        display: "inline-flex",
        alignItems: "initial",
        gap: "10px",
        width: "100%",
    },
    selectors: {
        width: "100%",
        maxWidth: "200px",
    },
});
const HomeComponent = () => {
    const classes = useStyles();
    const currentDate = dayjs();
    const twoYearsEarlier = currentDate.subtract(24, "months");
    const [allData, setAllData] = useState([]);
    const [dataByStation, setDataByStation] = useState({});
    const [station1, setStation1] = useState("FTS-3");
    const [station2, setStation2] = useState("FTS-37");
    const [station3, setStation3] = useState("");
    const [startDate, setStartDate] = useState(twoYearsEarlier.toDate());
    const [endDate, setEndDate] = useState(currentDate.toDate());
    const authCtx = useContext(AuthContext);

    const fetchData = async () => {
        try {
            const startJsDate = new Date(startDate);
            const endJsDate = new Date(endDate);

            const res = await API.getDevicesByDate(
                startJsDate,
                endJsDate,
                authCtx.token
            );

            const items = res.tests;
            const rows = items.map((item, index) => ({
                serial: index + 1,
                ...item,
            }));
            setAllData(rows);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [startDate, endDate]);

    useEffect(() => {
        if (!allData.length) return;

        const groupedByDayAndStation = {};

        allData.forEach((item) => {
            const dateObj = new Date(item.date);
            const day = `${dateObj.getDate()}-${
                dateObj.getMonth() + 1
            }-${dateObj.getFullYear()}`; // Format: DD-MM-YYYY

            if (!groupedByDayAndStation[item.station]) {
                groupedByDayAndStation[item.station] = {};
            }

            if (!groupedByDayAndStation[item.station][day]) {
                groupedByDayAndStation[item.station][day] = {
                    count: 0,
                    model: item.model,
                    "Average Low Noise": 0,
                    "Average Mid Noise": 0,
                    "Average High Noise": 0,
                    "Average Low Signal": 0,
                    "Average Mid Signal": 0,
                    "Average High Signal": 0,
                    "Average Low SNR": 0,
                    "Average Mid SNR": 0,
                    "Average High SNR": 0,
                };
            }

            groupedByDayAndStation[item.station][day]["Average Low Noise"] +=
                item.noiseFloor[0];
            groupedByDayAndStation[item.station][day]["Average Mid Noise"] +=
                item.noiseFloor[1];
            groupedByDayAndStation[item.station][day]["Average High Noise"] +=
                item.noiseFloor[2];
            groupedByDayAndStation[item.station][day]["Average Low Signal"] +=
                item.targetValues[1];
            groupedByDayAndStation[item.station][day]["Average Mid Signal"] +=
                item.targetValues[3];
            groupedByDayAndStation[item.station][day]["Average High Signal"] +=
                item.targetValues[5];
            groupedByDayAndStation[item.station][day]["Average Low SNR"] +=
                item.targetValues[1] - item.noiseFloor[0];
            groupedByDayAndStation[item.station][day]["Average Mid SNR"] +=
                item.targetValues[3] - item.noiseFloor[1];
            groupedByDayAndStation[item.station][day]["Average High SNR"] +=
                item.targetValues[5] - item.noiseFloor[2];

            groupedByDayAndStation[item.station][day].count++;
        });

        for (let station in groupedByDayAndStation) {
            for (let day in groupedByDayAndStation[station]) {
                const count = groupedByDayAndStation[station][day].count;
                groupedByDayAndStation[station][day]["Average Low Noise"] /= count;
                groupedByDayAndStation[station][day]["Average Low Noise"] -= 2579;
                groupedByDayAndStation[station][day]["Average Mid Noise"] /= count;
                groupedByDayAndStation[station][day]["Average Mid Noise"] -= 2319;
                groupedByDayAndStation[station][day]["Average High Noise"] /= count;
                groupedByDayAndStation[station][day]["Average High Noise"] -= 2139;
                groupedByDayAndStation[station][day]["Average Low Signal"] /= count;
                groupedByDayAndStation[station][day]["Average Low Signal"] -= 6718;
                groupedByDayAndStation[station][day]["Average Mid Signal"] /= count;
                groupedByDayAndStation[station][day]["Average Mid Signal"] -= 6926;
                groupedByDayAndStation[station][day]["Average High Signal"] /= count;
                groupedByDayAndStation[station][day]["Average High Signal"] -= 6906;
                groupedByDayAndStation[station][day]["Average Low SNR"] /= count;
                groupedByDayAndStation[station][day]["Average Low SNR"] -= 4139;
                groupedByDayAndStation[station][day]["Average Mid SNR"] /= count;
                groupedByDayAndStation[station][day]["Average Mid SNR"] -= 4588;
                groupedByDayAndStation[station][day]["Average High SNR"] /= count;
                groupedByDayAndStation[station][day]["Average High SNR"] -= 4741;

                delete groupedByDayAndStation[station][day].count; // Remove the count field after using it for the average calculation
            }
        }
        setDataByStation(groupedByDayAndStation);
    }, [allData]);

    const getAnnotations = (title, model) => {
        const annotations = [];
        let failCriteria, warningIndicator;

        switch (title) {
            case "Average Low Noise":
                if (model === "smart") {
                    [failCriteria, warningIndicator] = [871, 671];
                } else if (model === "ball") {
                    [failCriteria, warningIndicator] = [671, 471];
                }
                break;
            case "Average Mid Noise":
                if (model === "smart") {
                    [failCriteria, warningIndicator] = [581, 381];
                } else if (model === "ball") {
                    [failCriteria, warningIndicator] = [581, 381];
                }
                break;
            case "Average High Noise":
                if (model === "smart") {
                    [failCriteria, warningIndicator] = [511, 311];
                } else if (model === "ball") {
                    [failCriteria, warningIndicator] = [511, 311];
                }
                break;
            case "Average Low SNR":
                if (model === "smart") {
                    [failCriteria, warningIndicator] = [-739, -539];
                } else if (model === "ball") {
                    [failCriteria, warningIndicator] = [-539, -339];
                }
                break;
            case "Average Mid SNR":
                if (model === "smart") {
                    [failCriteria, warningIndicator] = [-488, -288];
                } else if (model === "ball") {
                    [failCriteria, warningIndicator] = [-488, -288];
                }
                break;
            case "Average High SNR":
                if (model === "smart") {
                    [failCriteria, warningIndicator] = [-416, -216];
                } else if (model === "ball") {
                    [failCriteria, warningIndicator] = [-416, -216];
                }
                break;
            default:
                return [];
        }

        if (failCriteria) {
            annotations.push({
                type: "line",
                mode: "horizontal",
                scaleID: "y",
                value: failCriteria,
                borderColor: "rgba(128, 0, 0, 0.5)",
                borderWidth: 2,
                label: {
                    display: true,
                    content: "Fail Criteria",
                    position: "end",
                    backgroundColor: "rgba(128, 0, 0, 0.5)",
                },
            });
        }

        if (warningIndicator) {
            annotations.push({
                type: "line",
                mode: "horizontal",
                scaleID: "y",
                value: warningIndicator,
                borderColor: "rgba(255, 165, 0, 0.5)",
                borderWidth: 2,
                label: {
                    display: true,
                    content: "Warning Indicator",
                    position: "end",
                    backgroundColor: "rgba(255, 165, 0, 0.5)",
                },
            });
        }

        return annotations;
    };

    const generateChartData = (title, specificModel) => {
        const sortDates = (a, b) => new Date(a) - new Date(b);

        const datesFromStation1 = Object.keys(dataByStation[station1] || {}).sort(
            sortDates
        );
        const datesFromStation2 = Object.keys(dataByStation[station2] || {}).sort(
            sortDates
        );
        const datesFromStation3 = station3
            ? Object.keys(dataByStation[station3] || {}).sort(sortDates)
            : [];

        const allDates = [
            ...new Set([
                ...datesFromStation1,
                ...datesFromStation2,
                ...datesFromStation3,
            ]),
        ].sort(sortDates);

        const filterDataByModel = (data, date) => {
            const entry = data[date];
            if (entry && entry.model === specificModel) {
                return entry[title];
            }
            return null;
        };

        const datasets = [
            {
                label: station1,
                data: allDates.map((date) =>
                    dataByStation[station1]
                        ? filterDataByModel(dataByStation[station1], date)
                        : null
                ),
                borderColor: "blue",
                borderWidth: 2,
                fill: false,
                spanGaps: true,
            },
            {
                label: station2,
                data: allDates.map((date) =>
                    dataByStation[station2]
                        ? filterDataByModel(dataByStation[station2], date)
                        : null
                ),
                borderColor: "red",
                borderWidth: 2,
                fill: false,
                spanGaps: true,
            },
        ];

        if (station3) {
            datasets.push({
                label: station3,
                data: allDates.map((date) =>
                    dataByStation[station3]
                        ? filterDataByModel(dataByStation[station3], date)
                        : null
                ),
                borderColor: "green",
                borderWidth: 2,
                fill: false,
                spanGaps: true,
            });
        }

        return {
            labels: allDates,
            datasets,
        };
    };

    const allModels = [...new Set(allData.map((item) => item.model))];

    const generateTitle = () => {
        let title = "";
        if (station1) {
            title += station1;
        }

        if (station2) {
            title += title ? ` vs ${station2}` : station2;
        }

        if (station3) {
            title += title ? ` vs ${station3}` : station3;
        }

        return title;
    };

    const modelFullName = (modelCode) => {
        if (modelCode === "smart") return "Smart Coach";
        if (modelCode === "ball") return "Ball Coach";
        else return "Model";
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={classes.root}>
                <Paper elevation={1} className={classes.paper}>
                    <Paper elevation={0} className={classes.paper}>
                        <div className={classes.division}>
                            <div className={classes.datepickerDivision}>
                                <DatePicker
                                    margin="normal"
                                    variant="outlined"
                                    label="Start date"
                                    value={dayjs(startDate)}
                                    onChange={setStartDate}
                                />

                                <DatePicker
                                    margin="normal"
                                    variant="outlined"
                                    label="End date"
                                    value={dayjs(endDate)}
                                    onChange={setEndDate}
                                />
                            </div>
                            <div className={classes.selectorDivision}>
                                <FormControl variant="outlined" className={classes.selectors}>
                                    <InputLabel>Station 1</InputLabel>
                                    <Select
                                        value={station1}
                                        onChange={(e) => setStation1(e.target.value)}
                                        label="Station 1"
                                    >
                                        <MenuItem value="">
                                            <em>Station 1</em>
                                        </MenuItem>
                                        {Object.keys(dataByStation).map((station) => (
                                            <MenuItem key={station} value={station}>
                                                {station}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    className={classes.selectors}
                                >
                                    <InputLabel>Station 2</InputLabel>
                                    <Select
                                        value={station2}
                                        onChange={(e) => setStation2(e.target.value)}
                                        label="Station 2"
                                    >
                                        <MenuItem value="">
                                            <em>Station 2</em>
                                        </MenuItem>
                                        {Object.keys(dataByStation).map((station) => (
                                            <MenuItem key={station} value={station}>
                                                {station}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    className={classes.selectors}
                                >
                                    <InputLabel>Station 3</InputLabel>
                                    <Select
                                        value={station3}
                                        onChange={(e) => setStation3(e.target.value)}
                                        label="Station 3"
                                    >
                                        <MenuItem value="">
                                            <em>Station 3</em>
                                        </MenuItem>
                                        {Object.keys(dataByStation).map((station) => (
                                            <MenuItem key={station} value={station}>
                                                {station}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Paper>

                    {station1 &&
                        station2 &&
                        allModels.map((model) => (
                            <div key={model}>
                                <Paper elevation={2} className={classes.paper}>
                                    <div className={classes.division}>
                                        <h2
                                            style={{ color: "#E34234", fontFamily: "sans-serif" }}
                                        >{`${modelFullName(model)} : ${generateTitle()}`}</h2>
                                    </div>
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {[
                                            "Average Low Noise",
                                            "Average Mid Noise",
                                            "Average High Noise",
                                            "Average Low Signal",
                                            "Average Mid Signal",
                                            "Average High Signal",
                                            "Average Low SNR",
                                            "Average Mid SNR",
                                            "Average High SNR",
                                        ].map((title) => (
                                            <div
                                                key={title}
                                                style={{ width: "33.33%", padding: "10px" }}
                                            >
                                                <div className={classes.division}>
                                                    <h3 style={{ color: "#36454F" }}>
                                                        {title.split(" ")[1] +
                                                            " Band " +
                                                            title.split(" ")[2]}
                                                    </h3>
                                                </div>
                                                <Line
                                                    data={generateChartData(title, model)}
                                                    height={150}
                                                    options={{
                                                        plugins: {
                                                            annotation: {
                                                                annotations: getAnnotations(title, model),
                                                            },
                                                        },
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </Paper>
                            </div>
                        ))}
                </Paper>
            </div>
        </LocalizationProvider>
    );
};

export default HomeComponent;
