import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const getRandomColor = () => {
    return `rgb(${Math.floor(Math.random() * 256)},${Math.floor(
        Math.random() * 256
    )},${Math.floor(Math.random() * 256)})`;
};

const LineChart = ({ data }) => {
    const datasets = data.map((item) => {
        return {
            data: item.nullCourse,
            label: item.serial.toString(),
            lineTension: 0.2,
            borderColor: getRandomColor(),
        };
    });

    const chartData = {
        labels: data.length > 0 ? data[0].nullCourse.map((_, idx) => idx + 1) : [],
        datasets,
    };

    return (
        <div>
            <Line
                data={chartData}
                height="600px"
                width="200px"
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    aspectRatio: 1,
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                }}
            />
        </div>
    );
};

export default LineChart;
