import { toISODate } from "./dates";

let appURL = "http://localhost:8008/api/"; 
let identityURL = "http://localhost:8010/identity/"; 
let oauthURL = 'http://localhost:8010/oauth/token'; //
let manufacturingURL = "http://localhost:8013/manufacturing/"; //


if (process.env.REACT_APP_ENV === "staging") {
    appURL = "https://app.api.staging.pocketradar-services.com/api/";
    identityURL = "https://app.api.staging.pocketradar-services.com/identity/";
    oauthURL = "https://app.api.staging.pocketradar-services.com/oauth/token";
    manufacturingURL = "https://app.api.staging.pocketradar-services.com/manufacturing/";

} else if (process.env.REACT_APP_ENV === "production") {
    appURL = "https://api.pocketradar.com/api/";
    identityURL = "https://api.pocketradar.com/identity/";
    oauthURL = "https://api.pocketradar.com/oauth/token";
    manufacturingURL = "https://api.pocketradar.com/manufacturing/";
}

class ApiInterface {
    static async login(email, password) {
        return fetch(oauthURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                'username': email,
                'password': password,
                'grant_type': 'password'
            })
        }).then(response => {
            return response.json()
        })
    }

    static async refreshToken(refreshToken) {
        return fetch(oauthURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                'refresh_token': refreshToken,
                'grant_type': 'refresh_token'
            })
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getDevicesByDate(startDate, endDate, token) {
        return await fetch(
            manufacturingURL + "v2/tests/devices" +
            "?start=" + toISODate(startDate) +
            "&end=" + toISODate(endDate),
            // v2/tests/devices?start=2000-01-01&end=2020-01-01
            {
                headers: {
                    'Authorization': "Bearer " + token
                }
            })
            .then((response) => response.json())
    }

    static async getDeviceBySerial(serial, token) {
        return await fetch(
            manufacturingURL + "v2/tests/devices/" + serial,
            {
                headers: {
                    'Authorization': "Bearer " + token
                }
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Failed to fetch device with serial ${serial}. Status: ${response.status}`);
                }
                return response.json();
            })
            .catch((error) => {
                console.error(`Error fetching device with serial ${serial}:`, error);
                throw error;
            });
    }

    static async insertDeviceRecord(payload, token) {
        const endpoint = manufacturingURL + "v2/tests/devices";
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error(`Status: ${response.status}`);
            }
            return response.status;

        } catch (error) {
            console.error("Error inserting device record:", error);
            throw error;
        }
    }

}

export default ApiInterface;