import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import API from "../ApiInterface";
import AuthContext from "../store/auth-context";
import { useNavigate } from "react-router-dom";

import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles({
    root: {
        width: "100%",
        marginTop: "10px",
        border: "none",
    },
    paper: {
        marginBottom: "10px",
        padding: "10px",
        border: "none",
    },

    division: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "left",
        gap: "10px",
        padding: "10px",
        width: "100%",
    },
    datepickerDivision: {
        display: "inline-flex",
        justifyContent: "initial",
        alignItems: "initial",
        gap: "10px",
        width: "100%",
    },
    selectorDivision: {
        justifyContent: "end",
        display: "inline-flex",
        alignItems: "initial",
        gap: "10px",
        width: "100%",
    },
    selectors: {
        width: "100%",
        maxWidth: "200px",
    },
});
const DeviceDetails = () => {
    const [devices, setDevices] = useState(null);
    const authCtx = useContext(AuthContext);
    const { serial } = useParams();
    const navigate = useNavigate();
    const classes = useStyles();

    const handleBackClick = () => {
        navigate("/datatable");
    };

    useEffect(() => {
        const fetchDeviceDetails = async () => {
            try {
                const response = await API.getDeviceBySerial(serial, authCtx.token);
                if (response && response.length > 0) {
                    setDevices(response);
                }
            } catch (error) {
                console.error("Error fetching device details:", error);
            }
        };

        fetchDeviceDetails();
    }, [serial, authCtx.token]);

    if (!devices) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <div className={classes.root}>
            <Box className="device-details">
                <Paper elevation={1} className={classes.paper}>
                    <IconButton aria-label="refresh" onClick={handleBackClick}>
                        <ArrowBackIcon fontSize="large" />
                    </IconButton>
                    <div>
                        <Typography
                            variant="h4"
                            gutterBottom
                            align="center"
                            color={"#E34234"}
                        >
                            Device Details
                        </Typography>
                        {devices.map((device, index) => (
                            <Accordion key={device.serial} defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index + 1}-content`}
                                    id={`panel${index + 1}-header`}
                                >
                                    <div className={classes.division}>
                                        <Typography variant="h5">{`Device Serial: ${
                                            device.serial
                                        }, Date: ${new Date(
                                            device.date
                                        ).toLocaleDateString()}, Station: ${
                                            device.station
                                        }`}</Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={classes.division}>
                                        <div>
                                            <List>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Release"
                                                        secondary={device.release}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Date"
                                                        secondary={new Date(
                                                            device.date
                                                        ).toLocaleDateString()}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Serial"
                                                        secondary={device.serial}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Mode"
                                                        secondary={device.mode}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Station"
                                                        secondary={device.station}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Firmware Name"
                                                        secondary={device.firmware.name}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Firmware Version"
                                                        secondary={device.firmware.version}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Temperature"
                                                        secondary={device.tempHumid[0]}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Humidity"
                                                        secondary={device.tempHumid[1]}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Has Reset"
                                                        secondary={device.hasReset ? "Yes" : "No"}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Station ID"
                                                        secondary={device.stationId}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Model"
                                                        secondary={device.model}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Status"
                                                        secondary={device.status}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Created At"
                                                        secondary={new Date(
                                                            device.createdAt
                                                        ).toLocaleString()}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="Updated At"
                                                        secondary={new Date(
                                                            device.updatedAt
                                                        ).toLocaleString()}
                                                    />
                                                </ListItem>
                                            </List>
                                        </div>
                                        <div>
                                            <List>
                                                <Typography gutterBottom color={"#E34234"}>
                                                    Log:
                                                </Typography>
                                                {device.log.map((logItem, index) => (
                                                    <ListItem key={index}>
                                                        <ListItemText primary={logItem} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </Paper>
            </Box>
        </div>
    );
};

export default DeviceDetails;
