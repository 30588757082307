import React, {useEffect, useState, useCallback } from 'react';
import API from '../ApiInterface';
import jwt_decode from 'jwt-decode';

let logoutTimer;
let refreshTimer;
let timeRemain;
let timeReset;

const AuthContext = React.createContext({
    token: '',
    refreshToken: '',
    isLoggedIn: false,
    timeRemain: '',
    login: (accessToken, refreshToken) => {
    },
    logout: () => {
    }
});

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjustedExpirationTime = new Date(expirationTime).getTime();

    return adjustedExpirationTime - currentTime;
};

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem('token');
    const storedRefreshToken = localStorage.getItem('refreshToken');
    const storedExpirationDate = localStorage.getItem('expirationTime');
    const remainingTime = calculateRemainingTime(storedExpirationDate);

    if (remainingTime <= 60000) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expirationTime');
        return null;
    }

    return {
        token: storedToken,
        refreshToken: storedRefreshToken,
        duration: remainingTime,
        timeRemain: remainingTime
    };
};

export const AuthContextProvider = (props) => {
    const renew = props.renew;
    const tokenData = retrieveStoredToken();
    let initialToken;
    let initialRefreshToken;
    if (tokenData) {
        initialToken = tokenData.token;
        initialRefreshToken = tokenData.refreshToken;
    }
    const [token, setToken] = useState(initialToken);
    const [refreshToken, setRefreshToken] = useState(initialRefreshToken);

    let hasPermission = false;
    if (token) {
        const decodedToken = jwt_decode(token);
        hasPermission = decodedToken.permissions &&
            // decodedToken.permissions.includes("DA") &&
            decodedToken.permissions.includes("MM"); // "MM"
    };

    const userIsLoggedIn = hasPermission;
    const logoutHandler = useCallback(() => {
        setToken(null);
        setRefreshToken(false);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expirationTime');

        if (logoutTimer) {
            clearTimeout(logoutTimer);
        }

        if (refreshTimer) {
            clearTimeout(refreshTimer);
        }
    }, []);

    const refreshTokenMethod = () => {
        API.refreshToken(tokenData.refreshToken).then(r => {
                console.log(r)
            }
        );
    };

    const loginHandler = (token, refreshToken, expirationTime, isRefresh) => {
        setToken(token);
        setRefreshToken(refreshToken);

        if (isRefresh) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('expirationTime');

            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('expirationTime', expirationTime);
        } else {
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('expirationTime', expirationTime);
        }

        const remainingTime = calculateRemainingTime(expirationTime);
        timeReset = calculateRemainingTime(expirationTime);

        if (isRefresh) {
            logoutTimer = setTimeout(logoutHandler, remainingTime);
        }
        if (!isRefresh) {
            logoutTimer = setTimeout(logoutHandler, remainingTime);
        }
    };

    useEffect(() => {
        if (tokenData) {
            logoutTimer = setTimeout(logoutHandler, tokenData.duration);
        }
    }, [tokenData, timeReset, logoutHandler])

    const contextValue = {
        token: token,
        refreshToken: refreshToken,
        isLoggedIn: userIsLoggedIn,
        hasPermission: hasPermission,
        login: loginHandler,
        logout: logoutHandler,
    };

    return (<AuthContext.Provider value={contextValue}>{props.children}
    </AuthContext.Provider>);
};

export default AuthContext;